import { create } from 'zustand';
import { IDeliveryCity } from '../api/getDeliveryCities/types';
import { getShops } from '../api/getShops';
import {
  ICity,
  IShop,
} from '../api/getShops/types';
import { useCartStore } from './cartStore';
import { useFiltersStore } from './filtersStore';

interface CityStoreState {
  city: string | null;
  cityName: string;
  shops: IShop[];
  defaultShop: IShop | null;
  cities: ICity[];
  allCities: IDeliveryCity[];
  cityHasShops: boolean;
  cityPopupInvisible: boolean;
  overlayVisible: boolean;
  allShops: IShop[];
  allStoresIds: number[];
  isGetCityShopsLoading: boolean;
}

interface CityStoreActions {
  getCityShops: (city: string) => void;
  setAllShops: (shops: ICity[]) => void;
  setCity: (city: ICity) => void;
  setAllCities: (cities: IDeliveryCity[]) => void;
  setCityPopupInvisible: (value: boolean) => void;
  setOverlayVisible: (value: boolean) => void;
  setAllShops2: (shops: IShop[]) => void;
}

export const useCityStore = create<
  CityStoreState & CityStoreActions
>((set, get) => ({
  city: 'default',
  cityName: 'unknown',
  shops: [],
  cities: [],
  allCities: [],
  cityHasShops: false,
  isGetCityShopsLoading: false,
  defaultShop: null,
  cityPopupInvisible: false,
  overlayVisible: true,
  allShops: [],
  allStoresIds: [],
  setAllShops2: (shops: IShop[]) => {
    set({
      allStores: shops,
    });
  },
  setOverlayVisible: (value: boolean) => {
    set({
      overlayVisible: value,
    });
  },
  setCityPopupInvisible: (value: boolean) => {
    set({
      cityPopupInvisible: value,
    });
  },
  getCityShops: async (city: string) => {
    if (!city) {
      set({
        shops: [],
      });
      set({
        cityHasShops: false,
      });
    } else {
      try {
        set({
          isGetCityShopsLoading: true,
        });
        const response = await getShops();
        set({
          shops:
            response?.data.find(
              (item) => item.code === city
            )?.shops || [],
        });
        set({
          cityHasShops: Boolean(
            response?.data.find(
              (item) => item.code === city
            )?.shops
          ),
        });
      } catch (error) {
        console.error(
          'Failed to get shops',
          error
        );
      } finally {
        set({
          isGetCityShopsLoading: false,
        });
      }
    }

    try {
      const response = await getShops();
      set({
        allStoresIds: response?.data
          .map((city) => [...city.shops])
          .flatMap((shops) => shops)
          .map((shop) => shop.id),
      });
    } catch (error) {
      console.error(
        'failed to get all stores',
        error
      );
    }
  },
  setAllShops: (cities: ICity[]) => {
    set({
      cities: cities,
    });
  },
  setCity: (city: ICity) => {
    set({
      city: city.code,
      cityName: city.name,
    });
    useCartStore.getState().getDeliveries();
    useCartStore.getState().initializeCart();
    useFiltersStore.getState().resetShopIds();
    get().getCityShops(city.code);

    localStorage.setItem(
      'selectedCity',
      JSON.stringify(city)
    );

    document.cookie = `selectedCity=${city?.code}; path=/; max-age=31536000`;
  },
  setAllCities: (cities: IDeliveryCity[]) => {
    set({
      allCities: cities,
    });
  },
}));

'use client';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { motion } from 'framer-motion';
import {
  ElementType,
  FC,
  ReactNode,
  useMemo,
} from 'react';

interface Props {
  className?: string;
  as?: ElementType;
  children?: ReactNode;
  initial?: {
    opacity?: number;
    y?: number | string;
    x?: number | string;
  };
  transition?: {
    ease?: number[] | string;
    duration?: number;
    delay?: number;
  };
  trigger: boolean;
  disableInViewAnimation?: boolean;
}

export const AnimatedTranslateWrapper: FC<
  Props
> = ({
  className,
  as = 'span',
  initial,
  transition,
  trigger,
  children,
  disableInViewAnimation = false,
}) => {
  const Component = useMemo(
    () => motion(as),
    [as]
  );

  return (
    <Component
      className={twMergeExt(cvaRoot(), className)}
      initial={
        disableInViewAnimation
          ? undefined
          : {
              opacity: initial?.opacity ?? 0,
              y: initial?.y ?? '2.4rem',
              x: initial?.x ?? 0,
            }
      }
      animate={
        disableInViewAnimation
          ? undefined
          : trigger
            ? { opacity: 1, y: 0, x: 0 }
            : undefined
      }
      transition={
        disableInViewAnimation
          ? undefined
          : {
              duration: transition?.duration ?? 1,
              ease: transition?.ease ?? [
                0.71, 0, 0.16, 1,
              ],
              delay: transition?.delay ?? 0,
            }
      }
    >
      {children}
    </Component>
  );
};

const cvaRoot = cva([
  'OpacityTranslateAnimation-cvaRoot',
]);

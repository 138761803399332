import { cva } from 'class-variance-authority';

export const cvaButtonWrapper = cva([
  'button.styles-cvaButtonWrapper',
  'inline-flex relative align-middle',
]);

export const cvaRoot = cva([
  'Button-cvaRoot group',
]);

export const cvaTranslateWrapper = cva(
  [
    'Button-cvaTranslateWrapper',
    'relative',
    'transition-transform duration-300 ease-out',
    'group-disabled/translateEffect:-translate-y-0',
    'group-hover/translateEffect:-translate-y-full',
    'group-active/translateEffect:-translate-y-full',
  ],
  {
    variants: {
      isLoading: {
        true: 'opacity-0',
        false: '',
      },
    },
  }
);

export const cvaHiddenContentWrapper = cva([
  'Button-cvaHiddenContentWrapper',
  'absolute top-0 left-0',
  'translate-y-full',
]);

export const cvaButton = cva(
  [
    'relative',
    'transition-all duration-300 group',
  ],
  {
    variants: {
      alreadyAdded: {
        true: [
          'before:!bg-[#3DC869] after:!bg-[#3DC869] hover:!text-cBlack',
        ],
      },
      yandexReviewLink: {
        true: [
          'before:!bg-[#DFFAD5] after:!bg-[#DFFAD5]',
        ],
      },
      hoverEffect: {
        translate:
          'group/translateEffect overflow-hidden',
        roundLight:
          'hover:bg-[#F2F2FC] hover:border-[#F2F2FC]',
        highlight: [
          'group/highlightEffect',
          'overflow-hidden',
          'before:pb-[150%] before:absolute before:left-[-10%] before:transform-gpu before:translate-y-[68%] before:scale-0 before:w-[120%] before:h-0 before:rounded-[50%]',
          'after:absolute after:top-0 after:left-0 after:transform-gpu after:translate-y-[calc(-100%-1px)] after:w-full after:h-full after:transition-transform after:ease-button-after after:duration-500',
          'hnotdisable:before:translate-y-0 hnotdisable:before:scale-[1.2] hnotdisable:before:transition-transform hnotdisable:before:duration-400 hnotdisable:before:ease-button-before',
          'hnotdisable:after:translate-y-0 hnotdisable:after:[transition-duration:0.05s] hnotdisable:after:[transition-delay:0.4s] hnotdisable:after:transition-transform hnotdisable:after:ease-button-after',
        ],
        color: [
          'group/color',
          'hover:text-cMediumGreen hover:[&_svg]:text-cMediumGreen',
        ],
        borderBottom: [
          'group/borderBottom',
          'before:bg-cMediumGreen before:w-full before:h-0.6 before:rounded-[0.6rem_0.6rem_0_0] before:absolute before:left-0 before:-bottom-[2.9rem] before:p-0 before:transition-all before:duration-300 before:pointer-events-none',
          'before:opacity-0 hover:before:opacity-100',
        ],
      },
      color: {
        mediumGreen: [
          'bg-cMediumGreen text-cWhite disabled:bg-opacity-[0.32]',
          'before:bg-cDarkGreen after:bg-cDarkGreen',
        ],
        red: 'bg-cRed bg-opacity-[0.08] text-cRed disabled:bg-opacity-[0.02]',
        mediumGreyOnWhite: [
          'bg-cMediumGreyOnWhite text-cBlack disabled:bg-opacity-[0.32]',
          'before:bg-cLightGreen after:bg-cLightGreen',
        ],
        mediumGreyOnWhiteHoverDarkGrey: [
          'bg-cMediumGreyOnWhite text-cBlack disabled:bg-opacity-[0.32]',
          'before:bg-cDarkGreyOnGrey after:bg-cDarkGreyOnGrey',
        ],
        lightGreen: [
          'bg-cLightGreenBtn text-cBlack disabled:bg-opacity-[0.32]',
          'before:bg-cMediumGreyOnWhite after:bg-cMediumGreyOnWhite',
        ],
        darkGreyOnGrey: [
          'bg-cDarkGreyOnGrey text-cBlack disabled:bg-opacity-[0.32]',
          'before:bg-cLightGreenBtn after:bg-cLightGreenBtn',
        ],
        lightGrey: [
          'bg-cLightGrey text-cBlack disabled:bg-opacity-[0.32]',
          'before:bg-cLightGreenBtn after:bg-cLightGreenBtn',
        ],
        lightGreyOnGreen: [
          'bg-cLightGrey text-cBlack disabled:bg-opacity-[0.32]',
          'before:bg-cLightGreenBtn after:bg-cLightGreenBtn',
        ],
        white: [
          'bg-cWhite',
          'before:bg-cLightGreenBtn after:bg-cLightGreenBtn',
        ],
        outlined: [
          'border-[1px] border-cBlack disabled:border-opacity-[0.32]',
        ],
        iconOnWhite: '',
        outlinedOnWhite: [
          [
            'border-[1px] border-cBlack disabled:border-opacity-[0.32]',
            'before:bg-cMediumGreyOnWhite after:bg-cMediumGreyOnWhite',
            'hnotdisable:border-cMediumGreyOnWhite',
          ],
        ],
        outlinedOnGrey: [
          [
            'border-[1px] border-cBlack disabled:border-opacity-[0.32]',
            'before:bg-cDarkGreyOnGrey after:bg-cDarkGreyOnGrey',
            'hnotdisable:border-cDarkGreyOnGrey',
          ],
        ],
        outlinedOnColor: [
          [
            'border-[1px] border-cBlack disabled:border-opacity-[0.32]',
            'before:bg-cWhite after:bg-cWhite',
            'hnotdisable:border-cWhite',
          ],
        ],
        outlined32:
          'border-[1px] border-cBlack32 disabled:border-opacity-[0.16]',
      },
      highlightByColor: {
        mediumGreen: '',
        red: 'transition-all hnotdisable:bg-cRed hnotdisable:text-cWhite hover:rounded-[10rem]',
        mediumGreyOnWhite: '',
        lightGreen: '',
        darkGreyOnGrey: '',
        lightGrey: 'transition-all ',
        white: '',
        outlined: [
          'transition-all ease-in-out hover:rounded-[10rem]',
          'group-disabled/highlightEffect:scale-100',
          'group-hnotdisable/highlightEffect:scale-105',
          'group-active/highlightEffect:scale-95',
        ],
        outlined32: [
          'transition-all hover:rounded-[10rem]',
          'disabled/highlightEffect:scale-100',
          'hnotdisable/highlightEffect:scale-105',
          'active/highlightEffect:scale-95',
        ],
      },
      size: {
        small:
          'inline-flex justify-center items-center min-h-4 rounded-[1.2rem] sm:min-h-4.8 pb-0.2 sm:rounded-[1.6rem]',
        medium:
          'inline-flex justify-center items-center min-h-4.8 rounded-[1.2rem] sm:min-h-5.6 pb-0.3 sm:rounded-[1.6rem]',
        big: 'inline-flex justify-center items-center min-w-[20.9rem] min-h-5.6 pb-0.3 rounded-[2rem] sm:min-w-[34rem] sm:min-h-8 sm:rounded-[3.2rem]',
      },
      isLoading: {
        true: [
          'before:!bg-[#3DC869] after:!bg-[#3DC869]',
        ],
        false: '',
      },
      isDisabled: {
        true: 'pointer-events-none opacity-[0.32]',
        false: '',
      },
    },
  }
);

export const cvaLoadingIconWrapper = cva([
  'Button-cvaLoadingIconWrapper',
  'absolute left-0 top-0 w-full h-full',
  'flex justify-center items-center z-10 group',
]);

export const cvaLoaderIcon = cva(
  [
    'Button-cvaLoaderIcon',
    'animate-loading-btn-spin',
    'w-2.4 h-2.4 group-hover:stroke-white',
  ],
  {
    variants: {
      color: {
        mediumGreyOnWhite:
          '[&>path]:stroke-[#3DC869]',
        outlinedOnWhite:
          '[&>path]:stroke-[#213D38]',
        white: '[&>path]:stroke-[#3DC869]',
        iconOnWhite: '[&>path]:stroke-[#3DC869]',
        mediumGreen: 'stroke-white',
        red: 'stroke-[#FF5E7F] group-hover:!stroke-[#FF5E7F]',
      },
    },
  }
);

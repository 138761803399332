import { API } from '../api';
import { axiosInstance } from '../axios';
import {
  DeliveryPointsParams,
  IDeliveryPointsData,
} from './types';

export const postDeliveryRussianPostPoints =
  async (body: DeliveryPointsParams) => {
    try {
      const result =
        await axiosInstance.post<IDeliveryPointsData>(
          API.postDeliveryRussianPostPoints,
          body,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      return result.data?.data;
    } catch (e: any) {
      console.error(e);
    }
  };

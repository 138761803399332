import {
  cvaButton,
  cvaButtonWrapper,
  cvaHiddenContentWrapper,
  cvaLoaderIcon,
  cvaLoadingIconWrapper,
  cvaRoot,
  cvaTranslateWrapper,
} from '@/shared/styles/button.styles';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { VariantProps } from 'class-variance-authority';
import Link from 'next/link';
import {
  ButtonHTMLAttributes,
  forwardRef,
  ReactNode,
} from 'react';
import LoaderIcon from '~/icons/btn_loader.svg';

import { useInView } from 'react-intersection-observer';
import { AnimatedTranslateWrapper } from './AnimatedTranslateWrapper';

interface Props
  extends Omit<
    ButtonHTMLAttributes<HTMLButtonElement>,
    'color'
  > {
  wrapperClassName?: string;
  translateWrapperClassName?: string;
  contentWrapperClassName?: string;
  color?: VariantProps<typeof cvaButton>['color'];
  size?: VariantProps<typeof cvaButton>['size'];
  hoverEffect?: VariantProps<
    typeof cvaButton
  >['hoverEffect'];
  badgeSlot?: ReactNode;
  href?: string;
  target?: string;
  isLoading?: boolean;
  disableInViewAnimation?: boolean;
  rootWrapperClassName?: string;
  animateWrapperClassName?: string;
  alreadyAdded?: boolean;
  yandexReviewLink?: boolean;
  heartIcon?: boolean;
  prefetch?: boolean | undefined;
}

export const Button = forwardRef<
  HTMLButtonElement,
  Props
>(
  (
    {
      className,
      wrapperClassName,
      translateWrapperClassName,
      contentWrapperClassName,
      color,
      size,
      hoverEffect,
      type = 'button',
      children,
      badgeSlot,
      href,
      target,
      isLoading,
      disableInViewAnimation = false,
      rootWrapperClassName,
      animateWrapperClassName,
      alreadyAdded = false,
      yandexReviewLink = false,
      heartIcon = false,
      prefetch = true,
      ...props
    },
    ref
  ) => {
    const { ref: buttonWrapperRef, inView } =
      useInView({
        triggerOnce: true,
        threshold: 1.0,
      });

    const hasInViewAnimation = () => {
      return (
        (hoverEffect === 'highlight' ||
          hoverEffect === 'roundLight') &&
        !disableInViewAnimation
      );
    };
    return (
      <div
        className={twMergeExt(
          cvaButtonWrapper(),
          rootWrapperClassName
        )}
        ref={buttonWrapperRef}
      >
        <AnimatedTranslateWrapper
          disableInViewAnimation={
            !hasInViewAnimation()
          }
          initial={{
            y: hasInViewAnimation() ? '3rem' : 0,
            opacity: hasInViewAnimation() ? 0 : 1,
          }}
          transition={{
            delay: hasInViewAnimation() ? 0.2 : 0,
            ease: 'easeInOut',
          }}
          trigger={inView}
          className={animateWrapperClassName}
        >
          {href ? (
            <Link
              href={href}
              prefetch={prefetch}
              ref={ref}
              target={target}
              className={twMergeExt(
                cvaRoot(),
                cvaButton({
                  hoverEffect,
                  color,
                  size,
                  yandexReviewLink,
                  highlightByColor:
                    hoverEffect === 'highlight'
                      ? color
                      : null,
                }),
                size === 'big' &&
                  [
                    'outlined',
                    'outlined32',
                  ].includes(color ?? '')
                  ? cvaButton({
                      hoverEffect: 'highlight',
                      highlightByColor: color,
                    })
                  : null,
                className
              )}
              {...props}
            >
              <div
                className={twMergeExt(
                  'overflow-hidden z-10 relative',
                  wrapperClassName
                )}
              >
                <div
                  className={twMergeExt(
                    cvaTranslateWrapper(),
                    translateWrapperClassName
                  )}
                >
                  <div
                    className={
                      contentWrapperClassName
                    }
                  >
                    {children}
                  </div>

                  {hoverEffect === 'translate' ? (
                    <div
                      className={twMergeExt(
                        cvaHiddenContentWrapper(),
                        contentWrapperClassName
                      )}
                    >
                      {children}
                    </div>
                  ) : null}
                </div>
              </div>

              {badgeSlot}
            </Link>
          ) : (
            <button
              ref={ref}
              className={twMergeExt(
                cvaRoot(),
                cvaButton({
                  alreadyAdded,
                  hoverEffect,
                  color,
                  size,
                  isLoading,
                  highlightByColor:
                    hoverEffect === 'highlight'
                      ? color
                      : null,
                }),
                size === 'big' &&
                  [
                    'outlined',
                    'outlined32',
                  ].includes(color ?? '')
                  ? cvaButton({
                      hoverEffect: 'highlight',
                      highlightByColor: color,
                    })
                  : null,
                className
              )}
              type={type}
              {...props}
            >
              <div
                className={twMergeExt(
                  'overflow-hidden z-10 relative',
                  wrapperClassName
                )}
              >
                <div
                  className={twMergeExt(
                    cvaTranslateWrapper({
                      isLoading,
                    }),
                    translateWrapperClassName
                  )}
                >
                  <div
                    className={
                      contentWrapperClassName
                    }
                  >
                    {children}
                  </div>

                  {hoverEffect === 'translate' ? (
                    <div
                      className={twMergeExt(
                        cvaHiddenContentWrapper(),
                        contentWrapperClassName
                      )}
                    >
                      {children}
                    </div>
                  ) : null}
                </div>
              </div>

              {isLoading ? (
                <div
                  className={cvaLoadingIconWrapper()}
                >
                  <LoaderIcon
                    className={cvaLoaderIcon({
                      color: heartIcon
                        ? 'red'
                        : color,
                    })}
                  />
                </div>
              ) : null}

              {badgeSlot}
            </button>
          )}
        </AnimatedTranslateWrapper>
      </div>
    );
  }
);

Button.displayName = 'Button';

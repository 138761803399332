import { API } from '../api';
import { axiosInstance } from '../axios';
import {
  DeliveryCdekTariffParams,
  IDeliveryCdekTariffData,
} from './types';

export const postDeliveryCdekTariff = async (
  body: DeliveryCdekTariffParams
) => {
  try {
    const result =
      await axiosInstance.post<IDeliveryCdekTariffData>(
        API.postDeliveryCdekTariff,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    return result.data?.data;
  } catch (e: any) {
    console.error(e);
  }
};

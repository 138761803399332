import shopsData from '@/prebuild/data/shops.json';

export const getShops = async () => {
  try {
    const result = shopsData;
    return result;
  } catch (e: any) {
    // console.error(e);
  }
};

'use client';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import {
  cva,
  VariantProps,
} from 'class-variance-authority';
import { motion } from 'framer-motion';
import {
  DOMAttributes,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { mergeRefs } from 'react-merge-refs';

interface Props {
  className?: string;
  children?: ReactNode;
  dangerouslySetInnerHTML?: DOMAttributes<HTMLDivElement>['dangerouslySetInnerHTML'];
  variant?: VariantProps<
    typeof cvaContainer
  >['variant'];
  inViewAnimation?: boolean;
  style?: React.CSSProperties;
}

export const Container = forwardRef(
  (
    {
      className,
      children,
      dangerouslySetInnerHTML,
      variant = 'small',
      inViewAnimation,
      style,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    // const refLocal = useRef<HTMLDivElement>(null);
    const { ref: refLocal, inView } = useInView({
      threshold: 0.1,
      triggerOnce: true,
    });

    const variants = {
      visible: {
        opacity: 1,
        y: '0rem',
      },
      hidden: {
        opacity: 0,
        y: '8rem',
      },
    };

    return (
      <motion.div
        variants={variants}
        initial={
          !inViewAnimation ? undefined : 'hidden'
        }
        animate={
          !inViewAnimation
            ? undefined
            : inView
              ? 'visible'
              : 'hidden'
        }
        style={style}
        exit={
          !inViewAnimation ? undefined : 'hidden'
        }
        ref={mergeRefs([ref, refLocal])}
        transition={{
          duration: 1,
          ease: 'easeInOut',
        }}
        className={twMergeExt(
          cvaContainer({ variant }),
          className
        )}
        dangerouslySetInnerHTML={
          dangerouslySetInnerHTML
        }
      >
        {children}
      </motion.div>
    );
  }
);

Container.displayName = 'Container';

const cvaContainer = cva(
  ['Container-cvaContainer', 'w-full', 'mx-auto'],
  {
    variants: {
      variant: {
        full: 'sm:max-w-[76.7rem] md:max-w-[102.4rem] lg:max-w-[144rem]',
        large:
          'px-2.4 sm:max-w-[76.7rem] sm:px-2.4 md:max-w-[102.4rem] md:px-1.8 lg:max-w-[144rem] lg:px-2.4',
        small:
          'px-2.4 sm:max-w-[76.7rem] sm:px-3.2 md:max-w-[102.4rem] md:px-4.2 lg:max-w-[144rem] lg:px-5.6',
      },
    },
  }
);

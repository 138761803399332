import cititesData from '@/prebuild/data/cities.json';

export const getDeliveryCities = async () => {
  try {
    const result: any = cititesData;
    return result.data;
  } catch (e: any) {
    console.error(e);
  }
};

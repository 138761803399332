import { API } from '../api';
import { axiosInstance } from '../axios';
import { IBasketData } from '../getBasket/types';
import { BasketDeleteArrayParams } from './types';

export const postBasketDeleteArray = async (
  body: BasketDeleteArrayParams
) => {
  try {
    const result =
      await axiosInstance.post<IBasketData>(
        API.postBasketDeleteArray,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    return result.data?.data;
  } catch (e: any) {
    console.error(e);
  }
};

import { API } from '../api';
import { axiosInstance } from '../axios';
import { IBasketData } from './types';

export const getBasket = async () => {
  try {
    const result =
      await axiosInstance.get<IBasketData>(
        API.getBasket
      );
    return result.data?.data;
  } catch (e: any) {
    console.error(e);
  }
};

import { extendTailwindMerge } from 'tailwind-merge';

export const twMergeExt = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        {
          text: [
            'h1',
            'h2',
            'h3',
            'h4',
            'h4Bold',
            'h5',
            'titleS',
            'bodyL',
            'bodyLSemibold',
            'bodyM',
            'bodyMSemibold',
            'bodyMBold',
            'bodyS',
            'bodySSemibold',
            'bodySBold',
            'bodyXS',
            'bodyXXS',
            'labelM',
            'labelL',
            'labelS',
            'factoidL',
            'factoidM',
            'bodyXSSemibold',
          ],
        },
      ],
    },
  },
});

import { API } from '../api';
import { axiosInstance } from '../axios';
import {
  DeliveryPointsParams,
  IDeliveryPointsData,
} from './types';

export const postDeliveryCdekPoints = async (
  body: DeliveryPointsParams
) => {
  try {
    const result =
      await axiosInstance.post<IDeliveryPointsData>(
        API.postDeliveryCdekPoints,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    return result.data?.data;
  } catch (e: any) {
    console.error(e);
  }
};
